@media (prefers-color-scheme: dark) {
  // This is the Midnight theme available here:
  // https://github.com/sn-extensions/midnight-theme
  // Licensed under AGPL-3.0
  :root {
    --primary-bg-color: #292937;
    --secondary-bg-color: #313142;
    --editor-bg-color: #202020;
    --border-color: #13131a;
    --ui-text-color: #d8d8d8;

    --sn-stylekit-info-color: #086dd6;
    --sn-stylekit-info-contrast-color: white;

    --sn-stylekit-neutral-color: #7c7c7c;
    --sn-stylekit-neutral-contrast-color: white;

    --sn-stylekit-success-color: #2b9612;
    --sn-stylekit-success-contrast-color: white;

    --sn-stylekit-warning-color: #f6a200;
    --sn-stylekit-warning-contrast-color: white;

    --sn-stylekit-danger-color: #f80324;
    --sn-stylekit-danger-contrast-color: white;

    --sn-desktop-titlebar-bg-color: var(--primary-bg-color);
    --sn-desktop-titlebar-border-color: var(--border-color);
    --sn-desktop-titlebar-ui-color: var(--ui-text-color);
    --sn-desktop-titlebar-ui-hover-color: var(--sn-stylekit-info-color);

    // StyleKit Vars
    --sn-stylekit-shadow-color: #20202b;

    --sn-stylekit-background-color: #20202b;
    --sn-stylekit-border-color: var(--border-color);
    --sn-stylekit-foreground-color: var(--ui-text-color);

    --sn-stylekit-contrast-background-color: var(--secondary-bg-color);
    --sn-stylekit-contrast-foreground-color: var(--ui-text-color);
    --sn-stylekit-contrast-border-color: var(--border-color);

    --sn-stylekit-secondary-background-color: #292937;
    --sn-stylekit-secondary-foreground-color: var(--ui-text-color);
    --sn-stylekit-secondary-border-color: var(--border-color);

    --sn-stylekit-secondary-contrast-background-color: #45445a;
    --sn-stylekit-secondary-contrast-foreground-color: var(--ui-text-color);
    --sn-stylekit-secondary-contrast-border-color: var(--border-color);

    --sn-stylekit-editor-background-color: var(--sn-stylekit-background-color);
    --sn-stylekit-editor-foreground-color: var(--sn-stylekit-foreground-color);

    --sn-stylekit-paragraph-text-color: var(--ui-text-color);

    --sn-stylekit-scrollbar-track-border-color: var(--border-color);
    --sn-stylekit-scrollbar-thumb-color: var(--sn-stylekit-info-color);
  }

  /* Adjust background color for Plain Textarea and CodeMirror 
  to be same as View Mode instead of a contrast background color. 
  This improves readability / usability in dark modes. */
  .sn-component .sk-input.contrast,
  .CodeMirror {
    background-color: var(--sn-stylekit-background-color);
  }
}
